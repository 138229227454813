
<script>
import BaseEdit from './baseEdit/Edit'
import clientObjectMixin from '../../share/mixins/clientObjectMixin'

export default {
  mixins: [
    BaseEdit,
    clientObjectMixin
  ],
  data () {
    return {
      events: null,
      headerTitle: { title: 'Stany procesu', description: 'Tryb edycji' },
      service: this.$route.params.service,
      loginModalBackUrlName: ``
    }
  },
  computed: {
    workflowId () { return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].id }
  },
  created () {
    this.getInitEvents()
    this.getInitLoginModalBackUrlName()
  },
  methods: {
    getInitEvents () {
      this.events = {
        stateSubmit: `${this.service}:main:stateSubmit`
      }
    },
    getInitLoginModalBackUrlName () {
      this.loginModalBackUrlName = `${this.service}_state_listing`
    }
  }
}
</script>
